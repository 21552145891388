<template>
  <div class="dashboard">
    <main-app-bar>
      <template v-slot:title> Challenges </template>

      <template v-slot:actions>
        <div class="app-bar--bottom-actions nav-pills">
          <v-btn :to="{ name: 'current.challenges' }" depressed replace>
            Current Challenges
          </v-btn>

          <v-btn :to="{ name: 'draft.challenges' }" depressed replace>
            Draft Challenges
          </v-btn>
          <v-btn :to="{ name: 'archived.challenges' }" depressed replace>
            Archived Challenges
          </v-btn>
        </div>

        <v-btn
          class="bg-primary-gradient ml-auto primary"
          :to="{ name: 'new.challenge' }"
          depressed
        >
          <v-icon class="mr-2"> {{ icons.add }} </v-icon>
          Add New Challenge
        </v-btn>
      </template>
    </main-app-bar>

    <div class="mt-5 px-12 mb-10">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'ChallengesPage',

  components: {
    MainAppBar,
  },

  data() {
    return {
      searchKey: '',
      addNew: false,
      loading: false,
      icons: {
        add: mdiPlus,
      },
    }
  },
}
</script>
